import React from 'react';
import "./style.scss"
import ScrollManager from "./scroll-manager";

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {modalOpen: false}; 
        this.scrollmanager = new ScrollManager();
    }
  handleClose = (e) => {
    if (!e.target.className.includes('modal_exterior')) {return e.preventDefault()}
    this.props.handleClose();
    }
    render() {
        if (!this.props.opened) {
            this.scrollmanager.allowScroll();
            return (<></>);
        } else {
            this.scrollmanager.blockScroll()
            return (
            <div className="modal_exterior" onClick={(e) => this.handleClose(e)}>
                <div className="modal">
                <div className="modal-body">
                    {this.props.children}
                </div>
                </div>
            </div>
            );
        }
    }
}

export {Modal};