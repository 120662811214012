const wait = (time) => {
    try {
        if (isNaN(time) || time < 0) throw new Error('Ocurrió un error en el timer: el argumento no es un número válido')
        return new Promise((res, rej) => {
            setTimeout(res, Math.floor(time));
        })
    } catch (error) {
        throw new Error('Ocurrió un error en el timer: ' + error.toString());
    }
}

export {wait};