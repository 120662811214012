import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { Header } from '../../components/header';
import { Titulo } from '../../components/titulo';
import { YoutubeEmbed } from '../../components/youtube';
import perfiles from "../../assets/data/perfiles.json"
import useAnalyticsEventTracker from '../../services/ganalitics';

class Main extends React.Component {
    render() {
      return (
          <>
            <Header navigation={this.props.navigation} />
            <div className='main'>
                <Banner navigation={this.props.navigation} />
                <Indicadores />
                {/* <YoutubeEmbed embedId="UPmOsYwajR0" /> */}
                <Materiales />
                <Soporte />
                <Testimonio />
                <Oferta navigation={this.props.navigation} />
            </div>
          </>
      );
    }
}

class Banner extends React.Component {
    render() {
      return (
        <>
          <div className='banner-principal'>
              <h1 className='gigantic'>Rentabiliza tu negocio de carpintería de aluminio y PVC.</h1>
              <p><strong>Gesfácil</strong> es un software de clase mundial, el cual permite la creación y gestión de presupuesto de manera fácil, rápida y segura</p>
          </div>
          <ContactameBtn text="Lo quiero" shadow displaced navigation={this.props.navigation} />
        </>
      );
    }
}

class ContactameBtn extends React.Component {
    classes = `contactame ${this.props.shadow ? 'shadow' : ''} ${this.props.displaced ? 'displaced' : ''} ${this.props.outline ? 'outline' : ''}`;
    gaEventTracker = useAnalyticsEventTracker('Contact us');
    render() {
      return (
        <Link className="btn" to="/contactame" onClick={()=>this.gaEventTracker('Lo quiero')}>
            <button className={this.classes} >{this.props.text}</button>
        </Link>
      );
    }
}

class Indicadores extends React.Component {
    render() {
      return (
        <div id="descripcion">
          <div className='indicadores'>
              <Indicador prevText="Hasta" valor="45" unidad="%" mensaje="Menos de merma." />
              <Indicador prevText="Más de" valor="26" unidad="" mensaje="Años en el mercado." />
              <Indicador prevText="Hasta" valor="70" unidad="%" mensaje="De ahorro en HH mensuales" />
          </div>
          <div id='hazlo-facil'>
              <Titulo text="Hazlo fácil" />
              <span id="uso-hazlo-facil">Más de 7.000 usuarios utilizan diariamente el software de clase mundial GesFácil.</span>
          </div>
        </div>
      );
    }
}

class Indicador extends React.Component {
    unitClasses = `unit ${this.props.unidad === "%" ? 'super':'sub'}index`
    render() {
      return (
        <>
          <div className='indicador'>
            <div className="value">
                <span className="prev_text subindex">{this.props.prevText}</span>
                <span className='number'>{this.props.valor}</span>
                <span className={this.unitClasses}>{this.props.unidad}</span>
            </div>
            <span className="message">{this.props.mensaje}</span>
          </div>
        </>
      );
    }
}


class Soporte extends React.Component {
    render() {
      return (
        <div id="soporte">
          <Titulo text="Continuidad" />
          <div className='tipos_soporte'>
              <SoporteItem titulo="Capacitación" link="#capacitacion-page" mensaje="Te ofrecemos capacitaciones contínuas para que saque el máximo provecho del software." />
              <SoporteItem titulo="Soporte" link="#soporte-page" mensaje="Canal de soporte 24/7 para que puedas resolver tus incidencias." />
              <SoporteItem titulo="Foros" link="#foros-page" mensaje="Accede a foros especializados con usuarios que llevan años utilizando el software." />
          </div>
        </div>
      );
    }
}
class SoporteItem extends React.Component {
    render() {
      return (
        <div className='tipo_soporte'>
          <div className='content'>
            <Titulo text={this.props.titulo} subtitle />
            <p className='mensaje'>{this.props.mensaje}</p>
            <a href={this.props.link}>ver más</a>
          </div>
        </div>
      );
    }
}

class Testimonio extends React.Component {
    render() {
      return (
        <div id="testimonio">
          <Titulo text="Testimonios" />
          <div className='testimonio'>
              <Comentario imagen="/assets/img/foto.jpg" nombre="Francisco Mandiola" cargo="Subgerente Comercial" empresa="Manacor SPA" mensaje="Desde que empezamos a utilizar Gesfacil, hemos logrado posicionarnos en el mercado 
              y generar una ventaja competitiva gracias a la rapidez y eficiencia que nos entrega este software a la hora de evaluar y presupuestar nuevos proyectos."/>
          </div>
        </div>
      );
    }
}

class Comentario extends React.Component {
    render() {
      return (
        <div className="comentario">
          <div className='foto'>
            <img src={'/assets/img/comilla.svg'} alt="Comilla" className='comilla'></img>
            <img src={'/assets/img/comilla.svg'} alt="Comilla" className='comilla'></img>
            <img alt={this.props.nombre} src={`${process.env.PUBLIC_URL}${this.props.imagen}`}></img>
          </div>
          <div className='text'>
            <span className='mensaje'>{this.props.mensaje}</span>
            <span className='nombre'>{this.props.nombre}</span>
            <span className='cargo'>{this.props.cargo}</span>
            <span className='empresa'>{this.props.empresa}</span>
          </div>
        </div>
      );
    }
}

class Oferta extends React.Component {
    render() {
      return (
        <div id="precio">
          <Titulo text="Contrátalo" />
          <div className='precio'>
              <Tarjeta titulo="1 licencia anual GesFacil" beneficios={[
                "Reducción de mermas en proyectos de pequeña y gran envergadura",
                "Hojas de corte completas y detalladas",
                "Obtención de cotizaciones inmediatas",
                "Optimización de perfiles y cristales",
                "Capacitación personalizada",
                "Capacitación para tus empleados",
                "Soporte de incidencias"
                ]} dcto="50" precio_regular="3.333" periodo="diarios" precio_oferta="1.700" navigation={this.props.navigation}/>
          </div>
        </div>
      );
    }
}

class Tarjeta extends React.Component {
    render() {
      return (
        <div className="card">
          <div className='head'>
            <div className="card_title">
              <h1>{this.props.titulo}</h1>
            </div>
            <div className='price'>
              <span className='discount'>{this.props.dcto}% Dcto.</span>
              <div className='sale_price'>
                <span className='amount'>${this.props.precio_oferta}</span>
                <span className='period'>{this.props.periodo}</span>
              </div>
              <span className='regular_price'>Antes ${this.props.precio_regular}</span>
            </div>
          </div>
            <div className="card_body">
              <div className="beneficios">
                {this.props.beneficios.map((text, index) => <span key={index} className="beneficio">{text}</span>)}
              </div>
            </div>
          <ContactameBtn text="Contrátalo" outline navigation={this.props.navigation}/>
        </div>
      );
    }
}
class Materiales extends React.Component {
  render() {
    return (
      <div id="materiales">
        <Titulo text="Materiales" />
          <Listado  />
      </div>
    );
  }
}

class Listado extends React.Component {
constructor(props) {
  super(props);
  this.state = {selected: 'aluminio', filtro: ''};
  // Binding this keyword
  this.selectTab = this.selectTab.bind(this)
}
selectTab = (selected) => {
  this.setState({selected});
}
updFiltro = (filtro) => {
  this.setState({filtro});
}
filtrar = (data) => {
  const find = this.state.filtro.toLowerCase().trim();
  if (!find) return data;
  const _data = {};
  for (const key in data) {
    if (key.toLowerCase().includes(find)) {
      _data[key] = data[key];
    } else if (data[key].some(el => el.toLowerCase().includes(find))) {
      _data[key] = [];
      for (const el of data[key]) {
        if (el.toLowerCase().includes(find)) {
          _data[key].push(el);
        }
      }
    }
  }
  return _data;
}
render() {
  return (
    <div className="card">
      <div className='card-head'>
        <div className="menu">
          <div className={`tab ${this.state.selected === 'aluminio' ? 'selected' : ''}`} name="aluminio" onClick={() => this.selectTab('aluminio')}><span>Aluminio</span></div>
          <div className={`tab ${this.state.selected === 'pvc' ? 'selected' : ''}`} name="pvc" onClick={() => this.selectTab('pvc')}><span>PVC</span></div>
          <div className={`tab ${this.state.selected === 'eternity' ? 'selected' : ''}`} name="eternity" onClick={() => this.selectTab('eternity')}><span>Eternity</span></div>
        </div>
        <Filtro udpFiltro={this.updFiltro}/>
      </div>
      <div className='card-body'>
        <TablaMats data={this.filtrar(perfiles[this.state.selected])}/>
      </div>
    </div>
  );
}
}
class Filtro extends React.Component {
render() {
  return (
    <div className='filtro'>
      <input type='text' onChange={(event) => {this.props.udpFiltro(event.target.value)}} placeholder="Filtrar"></input>
    </div>
  );
}
}
class TablaMats extends React.Component {
render() {
  const lista = [];
  let keyid = 100;
  for (const key in this.props.data) {
    for (const el of this.props.data[key]) {
      keyid++;
      lista.push(<div className='row' key={keyid}><span className="serie">{key}</span><span className='line'>{el}</span></div>)
    }
  }
  return (
    <div className="responsive-table">
      <div className='table-head'>
        <span>Linea</span>
        <span>Serie</span>
      </div>
      <div className='table-body'>
        {lista}
      </div>
      <div className='end'></div>
    </div>
  );
}
}

export {Main};

