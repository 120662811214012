import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import { Navigate } from "react-router-dom";
import { wait } from '../utils';
import { Main } from '../pages/main';
import { Contactame } from '../pages/contact';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-225406477-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

class Routing extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Ruta><Main /></Ruta>} />
                    <Route path="/home" element={<Ruta><Main /></Ruta>} />
                    <Route path="contactame" element={<Ruta><Contactame /></Ruta>} />
                    <Route path="*" element={ <Ruta><NotFound /></Ruta> } />
                </Routes>
            </BrowserRouter>
        );
    }
}

const Ruta  = (props) => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, [location]);
    return (
        <>{props.children}</>
    );
}
class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = { retorna : false };
    }
    async componentDidMount() {
       await wait(3000);
       this.setState({retorna: true});
    }
    render() {
        if(this.state.retorna) return(<Navigate to="/" />);
        return (
            <div style={{display: "flex", height: "100vh", width: "100%", textAlign: "center", justifyContent: "center", flexDirection: "column"}}>
                <h1>Ups!! Hemos llegado aquí por error.</h1>
                <h2>Tranquilo, te devolveremos al home en un instante.</h2>
            </div>
        );
    }
}

export default Routing