import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

class Header extends React.Component {
    render() {
      return (
        <div className='header' navigation={this.props.navigation}>
            <div className='logo'><Logo /></div>
            <div className='menu'><Menu /></div>
        </div>
      );
    }
}
class Logo extends React.Component {
  navega = this.props.navigation ? () => { this.props.navigation.navigate('home') } : () => {};
    render() {
      return (
        <Link className="logo" to="/">
          <img src={'/assets/img/logo.svg'} alt="Logo"></img>
        </Link>
      );
    }
}
class Menu extends React.Component {
    render() {
      return (
        <>
            <a href="#materiales" className='menu-option'>Materiales</a>
            <a href="#soporte" className='menu-option'>Soporte</a>
            <a href="#testimonio" className='menu-option'>Testimonios</a>
            <a href="#precio" className='menu-option'>Precio</a>
        </>
      );
    }
}
export {Header, Logo};