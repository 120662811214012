import React from 'react';
import {YoutubeEmbed} from "../../components/youtube";
import {Modal} from '../../components/modal';
class VideoModal extends React.Component {
    render() {
      return (
        <Modal opened={this.props.modalOpen} handleClose={this.props.handleClose}>
            <YoutubeEmbed embedId="UPmOsYwajR0" />;
        </Modal>
      );
    }
  }
  export {VideoModal};