import React from 'react';
import './style.scss';
import PropTypes from "prop-types";

class YoutubeEmbed extends React.Component {
    render() {
        return (
            <div className="video-responsive">
                <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${this.props.embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                />
            </div>
        );
    }
}

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export {YoutubeEmbed};