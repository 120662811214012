import React, {useEffect} from "react";
import './style.scss';
import {Logo} from '../../components/header';
import { VideoModal } from '../../modals/video';

class Contactame extends React.Component {
    render() {
      return (
        <div className="double_view">
          <Description />
          <Formulario />
        </div>
      );
    }
}
class Description extends React.Component {
    render() {
      return (
        <div className='description'>
            <Logo navigation={this.props.navigation} />
            <DescriptionBody />
        </div>
      );
    }
}
class DescriptionBody extends React.Component {
    render() {
      return (
        <div className='description_content'>
            <h1>Rentabiliza tu negocio de carpintería de aluminio y PVC.</h1>
            <p className='short_description'><strong>Gesfácil</strong> es un software de clase mundial el cual permite la creación y gestión de presupuesto de manera fácil, rápida y segura.</p>
            <p>Realiza presupuestos de manera rápida, con exactitud y seguridad en el manejo de los costos.</p>
            <p>Reduce las mermas de materiales, lo que te permitirá hacer más rentable tu negocio con un par  de clicks.</p>
            <p>Software desarrollado con el foco en la usabilidad de los usuarios.</p>
            <p>Capacitaciones contínuas a tu personal.</p>
            {/* <EnlaceVideo miniatura="assets/img/miniatura.png" message="Ver Gesfácil en acción" /> */}
        </div>
      );
    }
}
class EnlaceVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modalOpen: false}; 
    // Binding this keyword
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleOpen = () => {
    this.setState({modalOpen: true});
  }
  handleClose = () => {
    this.setState({modalOpen: false});
  }
  render() {    
    return (
      <>
        <div className='video_demo' onClick={this.handleOpen}>
            <img src={this.props.miniatura} alt="Gesfacil" className='miniatura'/>
            <span>{this.props.message}</span>
            <div className='button_play'><span>►</span></div>
        </div>
        <VideoModal handleClose={this.handleClose} modalOpen={this.state.modalOpen} />
      </>
    );
  }
}
class Formulario extends React.Component {
    render() {
      return (
        <div className='formulario'>
          <FORM_TEST />
        </div>
      );
    }
}
const FORM_TEST = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        const style = document.createElement('link');
        style.rel = "stylesheet"
        style.href = "./assets/scripts/formulario.css"
  
        document.body.appendChild(script);
        //document.body.appendChild(style);
  
        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                const form = window.hbspt.forms.create({
                    region: "na1",
                    portalId: '21901438',
                    formId: '93f5ed70-07c5-41eb-acb4-759dc11946a2',
                    target: '#hubspotForm'
                });
                form.onReady((e) => {
                    form._getComponent().getDOMNode().parentElement.parentElement.parentElement.firstChild.appendChild(style);
                });
                
            }
        });
    }, []);
  
    return (
        <div id="hubspotForm"></div>
    );
  
}
export { Contactame };