import React from 'react';
import { createRoot } from 'react-dom/client';
import './style.scss';
import Routing from './router';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-225406477-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<Routing tab="home" />);