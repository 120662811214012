const safeDocument = typeof document !== 'undefined' ? document : {};

class ScrollManager {
    constructor () {
        this.scrollBlocked = false;
        this.html = safeDocument.documentElement;
        this.body = safeDocument.body;
    }

    blockScroll() {
        if (!this.body || !this.body.style || this.scrollBlocked) return;
        const scrollBarWidth = window.innerWidth - this.html.clientWidth;
        const bodyPaddingRight = parseInt(window.getComputedStyle(this.body).getPropertyValue("padding-right")) || 0;
    
        /**
         * 1. Fixes a bug in iOS and desktop Safari whereby setting
         *    `overflow: hidden` on the html/body does not prevent scrolling.
         * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
         *    scroll if an `overflow-x` style is also applied to the body.
         */
        this.html.style.position = 'relative'; /* [1] */
        this.html.style.overflow = 'hidden'; /* [2] */
        this.html.style.height = '100%'; /* [2] */
        this.body.style.position = 'relative'; /* [1] */
        this.body.style.overflow = 'hidden'; /* [2] */
        this.body.style.height = '100%'; /* [2] */
        this.body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
        this.scrollBlocked = true;
    };
    allowScroll = () => {
        if (!this.body || !this.body.style || !this.scrollBlocked) return;
    
        this.html.style.position = '';
        this.html.style.overflow = '';
        this.html.style.height = '';
        this.body.style.position = '';
        this.body.style.overflow = '';
        this.body.style.height = '';
        this.body.style.paddingRight = '';
        this.scrollBlocked = false;
      };
}

export default ScrollManager;