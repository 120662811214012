import React from 'react';
import './style.scss';

class Titulo extends React.Component {
    classes = `${this.props.no_punto ? '' : 'punto'} ${this.props.subtitle ? 'subtitulo' : 'titulo'}`
    render() {
      return (
        <>
        <span className={this.classes}>{this.props.text}</span>
        </>
      );
    }
}
export {Titulo};